import { useState } from "react"
import axios from "axios"

export const Contact = () => {

  const [formName, setFormName] = useState('')
  const [formEmail, setFormEmail] = useState('')
  const [formPhone, setFormPhone] = useState('')
  const [formMessage, setFormMessage] = useState('')
  const [formError, setFormError] = useState(false)
  const [postSuccess, setPostSuccess] = useState(false)
  const [postFail, setPostFail] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)

  const sendEmail = async (e: any) => {
    e.preventDefault()
    setSendLoading(true)
    setPostSuccess(false)
    setPostFail(false)
    setFormError(false)
    if (!formName || !formEmail || !formMessage || !formPhone) {
      setSendLoading(false)
      setFormError(true)
    }
    else {
      setFormError(false)
      const emailObject = {
        'from_name': formName,
        'email': formEmail,
        'phone_number': formPhone,
        'message': formMessage
      }
      const res = await axios.post('/floors/email', emailObject)
      if(res.data == "success") {
        setSendLoading(false)
        setPostSuccess(true)
      }
      else if(res.data == "error") {
        setSendLoading(false)
        setPostFail(true)
      }
      setFormName('')
      setFormEmail('')
      setFormPhone('')
      setFormMessage('')
    }  
  }

  const renderText = () => {
    if(sendLoading) {
      return 'Sending...'
    }
    else if(postSuccess) {
      return 'Email Sent'
    }
    else if(postFail) {
      return 'Error Sending Email'
    }
    else if(formError) {
      return 'Please Complete the Form'
    }
    else {
      return 'nothing'
    }
  }

  return (
    <div className="w-screen h-screen relative">
      <div className="absolute h-[100%] left-[0%] lg:left-[15%] 2xl:left-[25%] w-[100%] lg:w-[70%] 2xl:w-[50%]" id="shadow">
        <div className="w-[100%] h-[100%] flex flex-col items-center">
          <form className="h-[100%] w-[100%] flex flex-col items-center justify-around text-[5vw] sm:text-[3vw] lg:text-[2.5vw] xl:text-[1.5vw] 2xl:text-[1vw]" onSubmit={sendEmail}>
            <div className="flex flex-col items-center">
              <h1 className="font-semibold font-bodoni text-[8vw] sm:text-[6vw] lg:text-[5vw] xl:text-[3.5vw] 2xl:text-[2.5vw]">Contact Us</h1>
              <h1 className="font-semibold font-bodoni text-[3vw] sm:text-[2vw] lg:text-[2vw] xl:text-[1.5vw] 2xl:text-[1vw]">Please email us at staci@reliableexpoxyfloors.com or use the form below</h1>
            </div>
            <input
              className="rounded-xl bg-slate-200 p-3 focus:outline-0 h-[8%] w-[75%] sm:w-[60%] xl:w-[50%]"
              type="text"
              value={formName}
              placeholder="Name"
              onChange={(e) => setFormName(e.target.value)}
            />
            <input
              className="rounded-xl bg-slate-200 p-3 focus:outline-0 h-[8%] w-[75%] sm:w-[60%] xl:w-[50%]"
              type="text"
              value={formEmail}
              placeholder="Email"
              onChange={(e) => setFormEmail(e.target.value)}
            />
            <input
              className="rounded-xl bg-slate-200 p-3 focus:outline-0 h-[8%] w-[75%] sm:w-[60%] xl:w-[50%]"
              type="text"
              value={formPhone}
              placeholder="Phone Number"
              onChange={(e) => setFormPhone(e.target.value)}
            />
            <textarea
              className="rounded-xl bg-slate-200 p-3 focus:outline-0 w-[75%] sm:w-[60%] xl:w-[50%] h-[20%]"
              id="message-box"
              value={formMessage}
              placeholder="Message"
              onChange={(e) => setFormMessage(e.target.value)}
            />
            <button className="w-[50%] sm:w-[30%] h-[8%] rounded-xl px-3 text-white border-2 border-black bg-[#2d3bc0] hover:scale-125" id="send-button">
              Send Email
            </button>
            <span className={`text-[5.5vw] sm:text-[3.25vw] lg:text-[2.75vw] xl:text-[1.75vw] 2xl:text-[1.25vw] ${sendLoading ? "text-black " : postSuccess ? "text-green-600" : postFail ? "text-red-500" : formError ? "text-red-500" : "text-white"}`}>{renderText()}</span>
          </form>
        </div>
      </div>
    </div>
  )
}